<template>
    <div class="page-content">
        <div class="banner-wrap"></div>
        <div class="main-content">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }"
                    >首页</el-breadcrumb-item
                >
                <el-breadcrumb-item :to="{ path: '/case/index' }"
                    >调解案件查询</el-breadcrumb-item
                >
                <el-breadcrumb-item
                    :to="{
                        path: '/case/list',
                        query: { searchFlag: searchFlag },
                    }"
                    >案件查询</el-breadcrumb-item
                >
                <el-breadcrumb-item>案件详情</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="info-wrap white-bg">
                <div class="step-wrap">
                    <!-- <el-steps :active="activeStep" align-center>
                        <el-step title="调解受理" :icon="Edit" />
                        <el-step title="正式调解" />
                        <el-step title="调解结案" />
                        <el-step title="诉讼准备" />
                    </el-steps> -->
                </div>
                <div class="item-info">
                    <p class="title">申请人信息</p>
                    <div class="item flex">
                        <p>
                            <span>单位名称：</span>
                            <span>{{ hideIdCard(applicant.orgName) }} </span>
                        </p>
                        <p>
                            <span>证照类型：</span>
                            <span>{{ applicant.licenseTypeName }} </span>
                        </p>
                        <p>
                            <span>证件号码：</span>
                            <span>{{ hideIdCard(applicant.licenseNo) }} </span>
                             
                        </p>
                    </div>
                </div>
                <div class="item-info">
                    <p class="title">被申请人信息</p>
                    <div class="item flex">
                        <p>
                            <span>案件查验码：</span>
                            <span>{{ validateCode }} </span>
                        </p>
                        <p>
                            <span>金调案号：</span>
                            <span>{{ caseDetail.mediateNum }} </span>
                        </p>
                        <p>
                            <span>被申请人：</span>
                            <span>{{respondent.name}} </span>
                            <!-- <span>{{ hideName(respondent.name) }} </span> -->
                        </p>
                        <p>
                            <span>诉调金额：</span>
                            <span>{{ caseDetail.appealAmount }}</span>
                        </p>
                        <p>
                            <span>调解员：</span>
                            <span>{{ caseDetail.mediatorName }}</span>
                        </p>
                        <!-- <p>
                            <span>民族：</span>
                            <span>{{ respondent.nation }}</span>
                        </p>
                        <p>
                            <span>证件号码：</span>
                            <span>{{ hideIdCard(respondent.idcard) }} </span>
                        </p> -->
                    </div>
                </div>
                <!-- <div class="item-info">
                    <p class="title">案件信息</p>
                    <div class="item flex">
                        <p>
                            <span>案件状态：</span>
                            <span>{{ form.caseStatusName }} </span>
                        </p>
                        <p>
                            <span>提交时间：</span>
                            <span>{{ form.createTime }}</span>
                        </p>
                        <p>
                            <span>调解案号：</span>
                            <span>{{ form.caseId }} </span>
                        </p>
                    </div>
                </div> -->

            </div>
            <div class="tj-wrap white-bg">
                <div class="step-wrap flex">
                    <p class="title">调解阶段:</p>
                    <ul class="flex">
                        <li
                            class="flex item-step"
                            :class="
                                activeStep >= index + 1
                                    ? 'active-item-step'
                                    : ''
                            "
                            v-for="(item, index) in tjList"
                            :key="index"
                        >
                            <p class="name">{{ item.name }}</p>
                            <p class="line"></p>
                            <div class="center">
                                <p class="arrow"></p>
                            </div>
                        </li>
                      
                    </ul>
                </div>
                <div class="line-wrap">
                    <p class="title"></p>
                    <div
                        class="all-line"
                        :class="activeStep >= 3 ? 'active-all-line' : ''"
                    >
                        <p class="line-01"></p>
                        <p class="line-02"></p>
                        <p class="line-03"></p>
                        <p class="arrow"></p>
                    </div>
                </div>
                <div class="step-wrap">
                    <p class="title">诉讼阶段:</p>
                    <ul class="flex">
                        <li
                            class="flex item-step"
                            :class="
                                activeStep >= index + 4
                                    ? 'active-item-step'
                                    : ''
                            "
                            v-for="(item, index) in ssList"
                            :key="index"
                        >
                            <p class="name">{{ item.name }}</p>
                            <p class="line"></p>
                            <div class="center">
                                <p class="arrow"></p>
                            </div>
                        </li>
                      
                    </ul>
                </div>
                <div class="desc">
                    调解员居中调解，依法有权了解案件事实有义务对案件法律问题进行详细解答。希望你方如实陈诉，若主张不存在欠款事实请出示相关证据。
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            id: this.$route.query.id,
            searchFlag: this.$route.query.searchFlag,
            validateCode: "",
            activeStep: "",
            form: {},
            applicant: {},
            respondent: {},
            caseDetail: {},
            tjList: [
                {
                    name: "调解受理",
                },
                {
                    name: "正式调解",
                },
                {
                    name: "调解结案",
                },
            ],
            ssList: [
                {
                    name: "诉讼准备",
                },
                {
                    name: "诉讼申请",
                },
                {
                    name: "正式立案",
                },
            ],
        };
    },
    created() {
        this.validateCode = localStorage.getItem("validateCode");
        this.getData();
    },
    methods: {
        async getData() {
            const params = {
                id: this.id,
                // id: "YHY001_0001_00002",
            };
            const rsp = await this.$http("getCaseDetail", params);
            if (rsp.code != 200) return;
            this.form = rsp.data;
            this.activeStep =  rsp.data && rsp.data.caseEvolve ? rsp.data.caseEvolve : '1';
            this.caseDetail = rsp.data.caseDetail;
            this.applicant = rsp.data.caseDetail.applicant; //当事人信息
            // this.mediateList = rsp.data.mediateList; //调解信息
            this.respondent = rsp.data.caseDetail.respondent; //被申请人信息
        },
        /**
         * 保留前后2位数
         * @param {idCard} 传入数据
         * 格式：53************01
         */
        hideIdCard(idCard) {
            if (idCard) {
                return idCard.replace(/(.{2}).*(.{2})/, "$1**********$2");
            }
        },
        // 姓名脱敏
        hideName(str) {
            if (null == str || str == undefined || str.length == 0) {
                return "";
            }
            if (str.length == 1) {
                return str;
            }
            if (str.length == 2) {
                return str.substring(0, 1) + "*"; //截取name 字符串截取第一个字符，
            }

            let starStr = "";
            for (let index = 0; index < str.length - 2; index++) {
                starStr += "*";
            }
            return (
                str.substring(0, 1) +
                starStr +
                str.substring(str.length - 1, str.length)
            );
        },
    },
};
</script>

<style lang="less" scoped>
.page-content {
    .main-content {
        width: 1200px;
        margin: auto;
    }
    .el-breadcrumb {
        padding: 40px 0 10px;
    }
    .banner-wrap {
        background-image: url("../../assets/images/med-banner.png");
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-position: 50%;
        height: 290px;
    }
    // 修改步骤条颜色
    ::v-deep .is-finish {
        color: #e60012;
        border-color: #e60012;
        font-weight: bold;
        .is-text {
            background-color: #e60012;
            color: #fff;
        }
        .el-step__line {
            background-color: #e60012;
        }
    }
    ::v-deep .is-process {
        color: #bdbdbd;
        border-color: #bdbdbd;
        font-weight: normal;
    }

    .info-wrap {
        padding: 39px 38px 30px;
        margin: 47px auto 0px;
        box-shadow: 0 2px 10px 0 hsla(0, 0%, 77.6%, 0.71);
        // .step-wrap {
        //     margin: 40px;

        // }
        .item-info {
            .title {
                height: 30px;
                width: 120px;
                background: #f7f7f7;
                line-height: 30px;
                font-size: 16px;
                color: #313131;
                font-weight: 700;
                border-left: 4px solid #e60012;
                text-align: center;
                margin: 20px 0;
            }
            .item {
                flex-flow: wrap;
                color: grey;
                font-size: 14px;
                p {
                    display: flex;
                    width: 49%;
                    margin: 15px 0 15px 5px;
                }

                span:nth-child(1) {
                    margin-right: 20px;
                    width: 100px;
                }
            }
        }
    }
    .tj-wrap {
        padding: 39px 38px 39px;
        margin: 20px auto 80px;
        box-shadow: 0 2px 10px 0 hsla(0, 0%, 77.6%, 0.71);
        .step-wrap {
            // margin: 40px 0;
            display: flex;
            align-items: center;
            color: grey;
            font-size: 14px;
        }
        ul {
            margin-left: 20px;

            .name {
                width: 120px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                border-radius: 20px;
                border: 1px solid #cfcfcf;
                margin: 0 20px;
            }
            .line {
                width: 100px;
                background-image: linear-gradient(
                    to right,
                    rgb(188, 185, 185) 35%,
                    rgba(255, 255, 255, 0) 0%
                ); /* 35%设置虚线点x轴上的长度 */
                background-position: center; /* top配置上边框位置的虚线 */
                background-size: 10px 1px; /* 第一个参数设置虚线点的间距；第二个参数设置虚线点y轴上的长度 */
                background-repeat: repeat-x;
            }
            .arrow {
                position: relative;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 5px 0 5px 10px;
                // border-width: 20px 0 20px 30px;
                border-color: transparent transparent transparent #cfcfcf;
            }
        }

        .active-item-step
        {
            .name {
                background: #ffe5e9;
                color: #e60012;
                border-color: #ffe5e9;
            }
            .line {
                background-image: linear-gradient(
                    to right,
                    rgb(225, 77, 77) 35%,
                    rgba(255, 255, 255, 0) 0%
                ); /* 35%设置虚线点x轴上的长度 */
            }
            .arrow {
                border-color: transparent transparent transparent #e14d4d;
            }
        }
        .item-step:last-child {
            .line {
                display: none;
            }
            .arrow {
                display: none;
            }
        }
        // 转折箭头
        .line-wrap {
            .all-line {
                .line-01 {
                    width: 1px;
                    height: 50px;
                    background-color: #d1cdcd;
                    margin-left: 703px;
                }
                .line-02 {
                    width: 543px;
                    height: 1px;
                    background-color: #d1cdcd;
                    margin-left: 161px;
                }
                .line-03 {
                    width: 1px;
                    height: 30px;
                    background-color: #d1cdcd;
                    margin-left: 161px;
                }
                .arrow {
                    margin-left: 155px;
                    width: 0px;
                    height: 0px;
                    border: 6px solid transparent;
                    border-top-color: #cfcfcf;
                }
            }
            .active-all-line {
                .line-01 {
                    background-color: #e60012;
                }
                .line-02 {
                    background-color: #e60012;
                }
                .line-03 {
                    background-color: #e60012;
                }
                .arrow {
                    border-top-color: #e60012;
                }
            }
        }
        .desc{
            padding: 20px;
            margin-top: 20px;
            background: #f5f5f5;
            color: #e60012;
            width: 720px;
            font-size: 14px;
            letter-spacing: 1px;
        }
    }
}
</style>